<template>
  <div>
    <section class="section">
      <div class="box">
        <div class="section is-title has-text-centered">
          <span class="is-title is-size-4"> Metrics breakdown </span>
        </div>
        <b-tabs
          position="is-centered"
          class="has-background-white"
          type="is-toggle-rounded"
          expanded
        >
          <b-tab-item label="Views">
            <section class="section">
              <div class="box">
                <CustomGraph label="Views" t="views" />
              </div>
            </section>
          </b-tab-item>
          <b-tab-item label="Posts">
            <section class="section">
              <div class="box">
                <CustomGraph label="Posts" t="posts" />
              </div>
            </section>
          </b-tab-item>
          <b-tab-item label="Hearts">
            <section class="section">
              <div class="box">
                <TotalGraph label="Hearts" t="total_likes" />
              </div>
            </section>
          </b-tab-item>
          <b-tab-item label="Comments">
            <section class="section">
              <div class="box">
                <TotalGraph label="Comments" t="total_comments" />
              </div>
            </section>
          </b-tab-item>
          <b-tab-item label="Shares">
            <section class="section">
              <div class="box">
                <TotalGraph label="Shares" t="total_shares" />
              </div>
            </section>
          </b-tab-item>
        </b-tabs>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CustomGraph from "@/components/hashtag/CustomGraph";
import TotalGraph from "@/components/hashtag/TotalGraph";

export default {
  components: { CustomGraph, TotalGraph },

  computed: {
    ...mapGetters("tiktok", ["tiktokUser"]),
    ...mapGetters("user", ["user"]),
  },
  methods: {
    getViewsData() {
      const data = {};
      this.tiktokUser.data.forEach(element => {
        if (element.views !== undefined) {
          const d = new Date(element.scan_date.seconds * 1000).toString();
          data[d] = element.views;
        }
      });
      return data;
    },
    getPostsData() {
      const data = {};
      this.tiktokUser.data.forEach(element => {
        if (element.posts !== undefined) {
          const d = new Date(element.scan_date.seconds * 1000).toString();
          data[d] = element.posts;
        }
      });
      return data;
    },
    getPostsDailyData() {
      const data = {};
      this.tiktokUser.data.forEach(element => {
        if (element.new_posts !== undefined) {
          const d = new Date(element.scan_date.seconds * 1000).toString();
          data[d] = element.new_posts;
        }
      });
      return data;
    },
  },
};
</script>

<style></style>
