<template>
  <section class="hero is-medium">
    <router-view />
  </section>
</template>

<script>
export default {};
</script>

<style></style>
