<template>
  <b-table :data="getSearchAlerts" :mobile-cards="true">
    <template slot-scope="props">
      <b-table-column field="name" label="name" sortable>
        {{ props.row.name }}
      </b-table-column>
      <b-table-column field="alert_type" label="type" sortable>
        {{ props.row.alert_type }}
      </b-table-column>
      <b-table-column field="alert_kind" label="on" sortable>
        {{ props.row.alert_kind }}
      </b-table-column>
      <b-table-column field="value" label="Value" sortable numeric>
        {{ props.row.value }}
      </b-table-column>
      <b-table-column field="triggered" label="Has triggered" sortable>
        {{ props.row.triggered }}
      </b-table-column>
      <b-table-column width="40">
        <b-button type="is-danger" outlined icon-right="delete" @click="toDelete(props.row)" />
        <b-modal :active.sync="isCardModalActive" :width="320">
          <div class="card">
            <div class="card-content">
              <div class="content">
                <div class="columns is-mobile">
                  <div class="column">
                    Are you sure ?
                  </div>
                </div>
                <div class="columns">
                  <div class="column has-text-right">
                    <b-button class="has-margin-right-5" @click="isCardModalActive = false">
                      Cancel
                    </b-button>
                    <b-button type="is-danger" @click="deleteSearch()">
                      Delete
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-modal>
      </b-table-column>
    </template>

    <template slot="empty">
      <section class="section">
        <div class="content has-text-grey has-text-centered">
          <p>
            <b-icon icon="emoticon-sad" size="is-large" />
          </p>
          <p>Nothing here.</p>
        </div>
      </section>
    </template>
  </b-table>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      isCardModalActive: false,
      current: "",
    };
  },
  computed: {
    ...mapGetters("tiktok", ["getSearchAlerts"]),
  },
  methods: {
    toDelete(t) {
      this.isCardModalActive = true;
      this.current = t;
    },
    deleteSearch() {
      this.$store.dispatch("tiktok/deleteAlert", {
        id: this.current.id,
        search_id: this.current.search_id,
      });
      this.isCardModalActive = false;
      this.current = "";
    },
  },
};
</script>

<style></style>
