<template>
  <div class="box">
    <div class="columns">
      <div class="column is-one-fifth">
        <b-icon icon="comment-text-multiple" size="is-large" type="is-success" />
      </div>
      <div class="column">
        <p class="is-size-5 has-text-grey">
          Total comments
        </p>
        <p v-if="tiktokUser.average_comment === 0" class="is-size-7 has-text-dark">
          Data will be updated soon
          <br />Please come back tomorrow
        </p>
        <p v-else class="is-size-3 has-text-dark">
          {{ getTotalComments() | formatNumber }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    sortedStat() {
      const arr = this.tiktokUser.stat.slice();
      arr.sort(
        (a, b) =>
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          b.scan_date.toDate() - a.scan_date.toDate()
      );
      return arr;
    },
    ...mapGetters("user", ["user"]),
    ...mapGetters("tiktok", ["tiktokUser"]),
  },
  methods: {
    getTotalComments() {
      const arr = this.sortedStat;

      if (arr.length > 0) {
        return arr[0].total_comment;
      }
      return "Will be fetched soon";
    },
  },
};
</script>

<style>
p.blur {
  -webkit-filter: blur(5px);
  filter: blur(5px);
}
</style>
