<template>
  <section class="section">
    <div class="box">
      <p class="subtitle has-text-centered">
        User Songs
        <br /><br />
      </p>
      <div class="columns">
        <div class="column">
          <div class="box">
            <b-table
              :data="data"
              :loading="loading"
              :paginated="true"
              :striped="true"
              :bordered="true"
              default-sort="upload_date"
              default-sort-direction="desc"
            >
              <template slot-scope="props">
                <b-table-column field="cover" class="custom" width="70">
                  <figure class="image is-64x64">
                    <img class="is-rounded" :src="props.row.cover" />
                  </figure>
                </b-table-column>
                <b-table-column field="name" label="Song Name" sortable>
                  <router-link :to="getRouterLink(props.row.song_id)">
                    {{ props.row.name }}
                  </router-link>
                </b-table-column>

                <b-table-column field="author" label="Author Name" sortable>
                  {{ props.row.author }}
                </b-table-column>
                <b-table-column field="upload_date" label="Upload Date" sortable>
                  {{ props.row.upload_date }}
                </b-table-column>
                <b-table-column width="40">
                  <a :href="getLink(props.row.url)" target="_blank">
                    <b-button type="is-link" outlined icon-right="link" />
                  </a>
                </b-table-column>
                <b-table-column width="40">
                  <b-button
                    type="is-primary"
                    outlined
                    icon-right="chart-line"
                    tag="router-link"
                    :to="getRouterLink(props.row.song_id)"
                  />
                </b-table-column>
              </template>

              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <p>
                      <b-icon icon="update" size="is-large" />
                    </p>
                    <p>Data will be updated soon</p>
                  </div>
                </section>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

import axios from "axios";
import firebase from "firebase";

export default {
  data() {
    return {
      data: [],
      loading: false,
      sortOrder: "desc",
      defaultSortOrder: "desc",
      perPage: 20,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters("user", ["user"]),
    ...mapGetters("tiktok", ["tiktokUser"]),
  },
  mounted() {
    this.$amplitude.logEvent("display user  songs");
    const id = this.tiktokUser.unique_id;
    this.loading = true;
    const vm = this;
    firebase
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(idToken => {
        const axiosConfig = {
          headers: {
            Authorization: `Bearer ${idToken}`,
            "Access-Control-Allow-Origin": "*",
          },
        };
        axios
          .get(`${process.env.VUE_APP_USER_SERVICE}/user/${id}/songs`, axiosConfig)
          .then(response => {
            vm.data = response.data;
            vm.loading = false;
          })
          .catch(error => {
            // this.error = error.data;
            vm.data = [];
            vm.loading = false;
            console.log(error);
          });
      })
      .catch(error => {
        console.log(error);
        vm.loading = false;
      });
  },
  methods: {
    getLink(id) {
      return `https://www.tiktok.com/music/${id}`;
    },
    getRouterLink(id) {
      return `/charts/${id}`;
    },
  },
};
</script>

<style>
.inset {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.5);
}
.tiktok {
  max-width: 25vh;
  height: auto;
  width: auto;
  max-height: 35vh;
}
</style>
