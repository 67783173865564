<template>
  <div>
    <section v-if="getPost === null" />
    <section v-else class="section">
      <div class="columns">
        <div class="column is-one-third">
          <div class="box">
            <div class="columns">
              <div class="column is-one-fifth">
                <b-icon icon="heart" size="is-large" type="is-danger" />
              </div>
              <div class="column">
                <p class="is-size-5 has-text-grey">
                  Hearts
                </p>
                <p class="is-size-3 has-text-dark">
                  {{ getPost.total_digg | formatNumber }}
                </p>
                <p />
              </div>
            </div>
          </div>
        </div>
        <div class="column is-one-third">
          <div class="box">
            <div class="columns">
              <div class="column is-one-fifth">
                <b-icon icon="share" size="is-large" />
              </div>
              <div class="column">
                <p class="is-size-5 has-text-grey">
                  Shares
                </p>
                <p class="is-size-3 has-text-dark">
                  {{ getPost.total_share | formatNumber }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-one-third">
          <div class="box">
            <div class="columns">
              <div class="column is-one-fifth">
                <b-icon icon="comment" size="is-large" type="is-success" />
              </div>
              <div class="column">
                <p class="is-size-5 has-text-grey">
                  Comments
                </p>
                <p class="is-size-3 has-text-dark">
                  {{ getPost.total_comment | formatNumber }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-one-third">
          <div class="box">
            <div class="columns">
              <div class="column is-one-fifth">
                <b-icon icon="play" size="is-large" type="is-succes" />
              </div>
              <div class="column">
                <p class="is-size-5 has-text-grey">
                  Views
                </p>
                <p class="is-size-3 has-text-dark">
                  {{ getPost.total_views | formatNumber }}
                </p>
                <p />
              </div>
            </div>
          </div>
        </div>
        <div class="column is-one-third">
          <div class="box">
            <div class="columns">
              <div class="column is-one-fifth">
                <b-icon icon="video" size="is-large" />
              </div>
              <div class="column">
                <p class="is-size-5 has-text-grey">
                  Video url
                </p>
                <p class="is-size-3 has-text-dark">
                  <a :href="generateVideoUrl" target="_blank"> link </a>
                </p>
                <p />
              </div>
            </div>
          </div>
        </div>
        <div class="column is-one-third">
          <div class="box">
            <div class="columns">
              <div class="column is-one-fifth">
                <b-icon icon="post" size="is-large" />
              </div>
              <div class="column">
                <p class="is-size-5 has-text-grey">
                  Creation date :
                </p>
                <p class="is-size-3 has-text-dark">
                  {{ dateInLocaleString }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PostGraph />
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import PostGraph from "@/components/post/PostGraph";

export default {
  components: { PostGraph },
  computed: {
    ...mapGetters("tiktok", ["getPost"]),
    generateVideoUrl() {
      return `https://www.tiktok.com/@${this.getPost.unique_id}/video/${this.getPost.id}`;
    },
    dateInLocaleString() {
      return new Date(this.getPost.create_time).toLocaleString();
    },
  },
  mounted() {
    this.$amplitude.logEvent("display user Result");
    this.$store.dispatch("tiktok/refreshPost", { id: this.$route.params.id });
  },
  destroyed() {
    this.$store.dispatch("tiktok/clearTracking");
  },
};
</script>
