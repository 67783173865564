<template>
  <section class="section">
    <h1 class="subtitle has-text-centered">
      Reports
    </h1>
    <div v-if="!loading">
      <div class="columns section">
        <div class="column is-half">
          <h2 class="subtitle has-text-centered">
            Most active days
          </h2>
          <column-chart class="box" :data="data_days" />
        </div>
        <div class="column is-half">
          <h2 class="subtitle has-text-centered">
            Most active hours (in GMT)
          </h2>
          <column-chart class="box" :data="data_hours" />
        </div>
      </div>
    </div>
    <div v-else>
      <div class="container">
        <b-loading v-model="isLoading" :is-full-page="false">
          <div class="columns is-centered">
            <div class="column is-half has-text-centered">
              <img class="svg-custom" src="../../assets/pentos-report.png" />
            </div>
          </div>
          <h1 class="has-text-centered">
            It will be updated soon!
          </h1>
        </b-loading>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

import axios from "axios";
import firebase from "firebase";
export default {
  data() {
    return {
      data_hours: [],
      data_days: [],
      loading: true,

      isLoading: false,
    };
  },
  computed: {
    ...mapGetters("user", ["user"]),
    ...mapGetters("tiktok", ["tiktokUser"]),
  },
  mounted() {
    this.$amplitude.logEvent("display hashtag report");
    const id = this.tiktokUser.unique_id;
    this.loading = true;
    const vm = this;
    firebase
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(idToken => {
        const axiosConfig = {
          headers: {
            Authorization: `Bearer ${idToken}`,
            "Access-Control-Allow-Origin": "*",
          },
        };
        axios
          .all([
            axios.get(`${process.env.VUE_APP_USER_SERVICE}/user/${id}/stats/day`, axiosConfig),
            axios.get(`${process.env.VUE_APP_USER_SERVICE}/user/${id}/stats/hour`, axiosConfig),
          ])
          .then(
            axios.spread((resp1, resp2) => {
              const r1 = [];
              resp1.data.forEach(e => {
                const t = [e.day, e.count];
                r1.push(t);
              });
              vm.data_days = r1;
              const r2 = [];
              resp2.data.forEach(e => {
                const t = [e.hour.toString() + " H", e.count];
                r2.push(t);
              });
              vm.data_hours = r2;

              vm.loading = false;
            })
          );
      })
      .catch(error => {
        console.log(error);
        vm.loading = false;
      });
  },
};
</script>

<style></style>
