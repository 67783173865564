<template>
  <div class="columns is-centered">
    <div class="column is-half">
      <div class="box">
        <article class="media">
          <div class="media-left">
            <figure v-if="hashtag.avatar !== undefined" class="image is-128x128">
              <img class="is-rounded" :src="hashtag.avatar" alt="Image" />
            </figure>
            <figure v-else class="image is-128-128">
              <b-icon icon="pound" size="is-large" />
            </figure>
          </div>
          <div class="media-content">
            <div class="content">
              <p>
                <span class="title"> #{{ hashtag.challenge_name }}</span>
              </p>
              <!-- <p>
              <span class="subtitle">@{{user.unique_id}}</span></p> -->
              <p>
                <span class="has-text-weight-bold"> {{ hashtag.views | formatNumber }} </span
                ><span class="has-text-grey"> views</span>
              </p>
            </div>
            <nav class="level">
              <div class="level-left" />
              <div class="level-right">
                <a class="level-item" aria-label="reply">
                  <b-button type="is-danger" @click="reset">Cancel</b-button>
                </a>
                <a class="level-item" aria-label="like">
                  <b-button type="is-success" @click="save">Validate</b-button>
                </a>
              </div>
            </nav>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import router from "@/router";

export default {
  props: ["hashtag"],
  methods: {
    reset() {
      this.$emit("reset");
    },
    save() {
      store.dispatch("tiktok/saveTrackingHashtag", this.hashtag);
      router.push("/hashtags");
    },
  },
};
</script>

<style></style>
