<template>
  <div>
    <section class="section">
      <div class="columns is-centered">
        <div class="column has-text-centered">
          <figure v-if="tiktokUser.avatar !== ''" class="image container is-128x128">
            <img class="is-rounded" :src="tiktokUser.avatar" />
          </figure>
          <p class="title is-3 has-margin-top-10">#{{ tiktokUser.challenge_name }}</p>
          <p v-if="tiktokUser.modification_date !== undefined" class="has-text-grey-light">
            Last updated {{ getLastModification }} ago
          </p>
        </div>
      </div>
      <div class="columns is-centered">
        <div class="column has-text-centered">
          <b-tooltip
            label="Coming soon ! Get email notification, when a post or the hashtag hits a overperforming score set here."
            position="is-top"
          >
            <b-button type="is-primary is-rounded is-outlined" disabled>
              Create Viral Alert
            </b-button>
          </b-tooltip>
        </div>
      </div>
      <div>
        <div v-if="tiktokUser.modification_date === undefined">
          <div class="columns is-centered">
            <div class="column is-half has-text-centered">
              <span class="subtitle"
                >We are fetching your data, it takes up to 24h to be updated, please come back
                tomorrow!</span
              >
            </div>
          </div>
          <div class="columns is-centered">
            <div class="column is-half">
              <b-progress type="is-primary" />
            </div>
          </div>
        </div>

        <div v-else class="box">
          <b-tabs :destroy-on-hide="true">
            <b-tab-item label="Overview">
              <div class="columns">
                <div class="column is-one-third">
                  <div class="box">
                    <div class="columns">
                      <div class="column is-one-fifth">
                        <b-icon icon="comment" size="is-large" type="is-success" />
                      </div>
                      <div class="column">
                        <p class="is-size-5 has-text-grey">
                          Average comments
                        </p>
                        <p class="is-size-3 has-text-dark">
                          {{ tiktokUser.average_comment | formatNumber }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="column is-one-third">
                  <div class="box">
                    <div class="columns">
                      <div class="column is-one-fifth">
                        <b-icon icon="heartbeat" size="is-large" type="is-danger" pack="fas" />
                      </div>
                      <div class="column">
                        <p class="is-size-5 has-text-grey">
                          Average likes
                        </p>
                        <p class="is-size-3 has-text-dark">
                          {{ tiktokUser.average_like | formatNumber }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="column is-one-third">
                  <div class="box">
                    <div class="columns">
                      <div class="column is-one-fifth">
                        <b-icon icon="eye" size="is-large" type="is-info" pack="fas" />
                      </div>
                      <div class="column ">
                        <p class="is-size-5 has-text-grey">
                          Views
                        </p>
                        <p class="is-size-3 has-text-dark">
                          {{ tiktokUser.views | formatNumber }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <HashtagGraph />
            </b-tab-item>
            <b-tab-item label="Reports">
              <HashtagReport />
            </b-tab-item>
            <b-tab-item label="Videos">
              <HashtagVideo />
            </b-tab-item>
          </b-tabs>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import axios from "axios";
import HashtagGraph from "@/components/hashtag/HashtagGraph";
import HashtagVideo from "@/components/hashtag/HashtagVideo";
import HashtagReport from "@/components/hashtag/HashtagReport";

const prettyMilliseconds = require("pretty-ms");

export default {
  components: { HashtagGraph, HashtagVideo, HashtagReport },
  computed: {
    ...mapGetters("tiktok", ["tiktokUser"]),
    ...mapGetters("user", ["user"]),
    getLastModification() {
      const startDate = new Date();
      const last = new Date(this.tiktokUser.modification_date.seconds * 1000);
      return prettyMilliseconds(startDate - last);
    },
  },
  mounted() {
    this.$amplitude.logEvent("Show hashtag Result");
    this.$store.dispatch("tiktok/getTracking", { id: this.$route.params.id });
  },
  destroyed() {
    this.$store.dispatch("tiktok/clearTracking");
  },
  methods: {
    averageView() {
      return (this.tiktokUser.views / this.tiktokUser.posts).toFixed(0);
    },
    exportToCSV() {
      const id = this.tiktokUser.challenge_name;
      const user = this.$store.getters["auth/authenticatedUser"].uid;
      this.loading = true;
      const vm = this;
      axios
        .get(`${process.env.VUE_APP_API}/csv/hashtag/?id=${id}&user=${user}`)
        .then(response => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", `${id}.csv`);
          document.body.appendChild(fileLink);

          fileLink.click();
          // handle success
          vm.loading = false;
        })
        .catch(error => {
          // this.error = error.data;
          console.log(error);
          vm.loading = false;
        });
    },
  },
};
</script>

<style></style>
