<template>
  <div>
    <section class="section">
      <div class="box">
        <div class="section is-title has-text-centered">
          <span class="is-title is-size-4"> Metrics breakdown </span>
        </div>
        <b-tabs
          position="is-centered"
          class="has-background-white"
          type="is-toggle-rounded"
          expanded
        >
          <b-tab-item label="Posts">
            <section class="section">
              <div class="box">
                <PostGraph />
              </div>
            </section>
          </b-tab-item>
          <b-tab-item label="Views">
            <section class="section">
              <div class="box">
                <Graph label="Views" t="total_views" />
              </div>
            </section>
          </b-tab-item>
          <b-tab-item label="Hearts">
            <section class="section">
              <div class="box">
                <Graph label="Hearts" t="total_likes" />
              </div>
            </section>
          </b-tab-item>
          <b-tab-item label="Comments">
            <section class="section">
              <div class="box">
                <Graph label="Comments" t="total_comments" />
              </div>
            </section>
          </b-tab-item>
          <b-tab-item label="Shares">
            <section class="section">
              <div class="box">
                <Graph label="Shares" t="total_shares" />
              </div>
            </section>
          </b-tab-item>
        </b-tabs>
      </div>
    </section>
  </div>
</template>

<script>
import PostGraph from "@/components/music/PostGraph";
import Graph from "@/components/music/Graph";

export default {
  components: { PostGraph, Graph },
};
// total_posts
</script>

<style></style>
