<template>
  <section class="section">
    <h1 class="subtitle has-text-centered">
      Reports
    </h1>
    <div v-if="!loading">
      <div class="columns section">
        <div class="column is-half">
          <h2 class="subtitle has-text-centered">
            Average posts per days
          </h2>
          <column-chart class="box" :data="data_days" />
        </div>
        <div class="column is-half">
          <h2 class="subtitle has-text-centered">
            Average posts per hours
          </h2>
          <column-chart class="box" :data="data_hours" />
        </div>
      </div>
      <div class="columns section">
        <div class="column is-half">
          <h2 class="subtitle has-text-centered">
            Hashtags used
          </h2>
          <column-chart :data="data_hashtags" />
        </div>
        <div class="column is-half">
          <h2 class="subtitle has-text-centered">
            Mentions
          </h2>
          <bar-chart :data="data_mentions" />
        </div>
      </div>
    </div>
    <div v-else>
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-half has-text-centered">
            <img class="svg-custom" src="../../assets/pentos-report.png" />
          </div>
        </div>
        <h1 class="has-text-centered">
          It will be updated soon!
        </h1>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

import axios from "axios";
import firebase from "firebase";
export default {
  data() {
    return {
      data_hours: [],
      data_days: [],
      data_mentions: [],
      data_hashtags: [],
      loading: true,

      isLoading: false,
    };
  },
  computed: {
    ...mapGetters("user", ["user"]),
    ...mapGetters("tiktok", ["getMusic"]),
  },
  mounted() {
    this.$amplitude.logEvent("display music report");
    const { id } = this.getMusic;
    this.loading = true;
    const vm = this;
    firebase
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(idToken => {
        const axiosConfig = {
          headers: {
            Authorization: `Bearer ${idToken}`,
            "Access-Control-Allow-Origin": "*",
          },
        };
        axios
          .all([
            axios.get(`${process.env.VUE_APP_USER_SERVICE}/song/${id}/stats/day`, axiosConfig),
            axios.get(`${process.env.VUE_APP_USER_SERVICE}/song/${id}/stats/hour`, axiosConfig),
            axios.get(`${process.env.VUE_APP_USER_SERVICE}/song/${id}/stats/mention`, axiosConfig),
            axios.get(`${process.env.VUE_APP_USER_SERVICE}/song/${id}/stats/hashtag`, axiosConfig),
          ])
          .then(
            axios.spread((resp1, resp2, resp3, resp4) => {
              const r1 = [];
              resp1.data.forEach(e => {
                const t = [e.day, e.count];
                r1.push(t);
              });
              vm.data_days = r1;
              const r2 = [];
              resp2.data.forEach(e => {
                const t = [e.hour.toString() + " H", e.count];
                r2.push(t);
              });
              vm.data_hours = r2;
              const r3 = [];
              resp3.data.forEach(e => {
                const t = [e.user, e.count];
                r3.push(t);
              });
              vm.data_mentions = r3;
              const r4 = [];
              resp4.data.forEach(e => {
                const t = [e.hashtag, e.count];
                r4.push(t);
              });
              vm.data_hashtags = r4;

              vm.loading = false;
            })
          );
      })
      .catch(error => {
        console.log(error);
        vm.loading = false;
      });
  },
};
</script>

<style></style>
