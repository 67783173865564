<template>
  <div>
    <section class="section">
      <div class="box">
        <div class="section is-title has-text-centered">
          <span class="is-title is-size-4"> Metrics breakdown </span>
          <b-button type="is-primary" @click="exportAllCSV()">
            Export all to csv
          </b-button>
        </div>
        <b-tabs
          position="is-centered"
          class="has-background-white"
          type="is-toggle-rounded"
          expanded
        >
          <b-tab-item label="Fans">
            <section class="section">
              <div class="box">
                <Graph label="Fans" t="fans" />
              </div>
            </section>
          </b-tab-item>
          <b-tab-item label="Hearts">
            <section class="section">
              <div class="box">
                <Graph label="Hearts" t="heart" />
              </div>
            </section>
          </b-tab-item>
          <b-tab-item label="Following">
            <section class="section">
              <div class="box">
                <Graph label="Following" t="following" />
              </div>
            </section>
          </b-tab-item>
          <b-tab-item label="Videos">
            <section class="section">
              <div class="box">
                <Graph label="Videos" t="video" />
              </div>
            </section>
          </b-tab-item>
          <b-tab-item label="Views">
            <section class="section">
              <div class="box">
                <Graph label="Views" t="views" />
              </div>
            </section>
          </b-tab-item>
          <b-tab-item label="Comments">
            <section class="section">
              <div class="box">
                <CommentGraph label="Comments" t="total_comment" />
              </div>
            </section>
          </b-tab-item>
        </b-tabs>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Graph from "@/components/charts/Graph";
import CommentGraph from "@/components/user/CommentGraph";

export default {
  components: { Graph, CommentGraph },

  computed: {
    ...mapGetters("tiktok", ["tiktokUser"]),
  },
  methods: {
    exportAllCSV() {
      const data = this.tiktokUser.data.slice();
      // eslint-disable-next-line require-jsdoc
      function compare(a, b) {
        return (
          new Date(b.scan_date.seconds * 1000).getTime() -
          new Date(a.scan_date.seconds * 1000).getTime()
        );
      }
      // eslint-disable-next-line require-jsdoc
      function format(a) {
        if (a === undefined) {
          return "";
        }
        return a;
      }
      let csv =
        "date,fans,fans_gained,hearts,hearts_gained,following,following_gained,videos,new_videos,views,views_gained,total comments";
      csv += "\n";
      data.sort(compare);
      const stats = this.tiktokUser.stat;
      data.forEach(element => {
        const stat = stats.find(e => e.scan_date.seconds === element.scan_date.seconds);
        const cmt = stat !== undefined ? stat.total_comment : "";
        const d = new Date(element.scan_date.seconds * 1000);
        csv +=
          d.toISOString() +
          "," +
          format(element.fans) +
          "," +
          format(element.fans_gained) +
          "," +
          format(element.heart) +
          "," +
          format(element.heart_gained) +
          "," +
          format(element.following) +
          "," +
          format(element.following_gained) +
          "," +
          format(element.video) +
          "," +
          format(element.new_video) +
          "," +
          format(element.views) +
          "," +
          format(element.views_gained) +
          "," +
          format(cmt);

        csv += "\n";
      });
      const hiddenElement = document.createElement("a");
      hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
      hiddenElement.target = "_blank";
      hiddenElement.download = "export.csv";
      hiddenElement.click();
    },
  },
};
</script>

<style></style>
