<template>
  <div class="columns is-centered">
    <div class="column is-one-third">
      <div class="card">
        <div class="" />

        <div class="card-content">
          <div class="media">
            <div class="media-left">
              <figure class="image  rounded is-128x128">
                <img
                  class="is-rounded"
                  :src="post.snippet.thumbnails.medium.url"
                  alt="Placeholder image"
                />
              </figure>
            </div>
            <div class="media-content">
              <p class="title is-4">
                {{ post.snippet.title }}
              </p>
              <p class="has-text-weight-semibold is-5">
                Description
              </p>
              <p class="subtitle is-6">
                {{ post.snippet.description }}
              </p>
            </div>
          </div>
          <nav class="level">
            <div class="level-left" />
            <div class="level-right">
              <a class="level-item" aria-label="reply">
                <b-button type="is-danger" @click="reset">Cancel</b-button>
              </a>
              <a class="level-item" aria-label="like">
                <b-button type="is-success" @click="save">Validate</b-button>
              </a>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import router from "@/router";

export default {
  props: ["post"],
  methods: {
    reset() {
      this.$emit("reset");
    },
    save() {
      store.dispatch("tiktok/saveYoutubeChannel", this.post);
      router.push("/dashboard");
    },
  },
};
</script>

<style scoped></style>
