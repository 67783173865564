<template>
  <div class="columns is-centered">
    <div class="column is-half">
      <div class="card">
        <div class="card-image">
          <figure class="image is-2by3">
            <img :src="post.video_cover" alt="Placeholder image" />
          </figure>
        </div>

        <div class="card-content">
          <div class="media">
            <div class="media-content">
              <div class="content">
                <p>
                  <span class="subtitle">{{ post.text }}</span>
                </p>
                <p />
                <p>
                  <span class="has-text-weight-bold">{{ post.digg_count | formatNumber }}</span>
                  <span class="has-text-grey"> hearts,</span>
                  <span class="has-text-weight-bold">{{ post.share_count | formatNumber }}</span>
                  <span class="has-text-grey"> shares,</span>
                  <span class="has-text-weight-bold">{{ post.comment_count | formatNumber }}</span>
                  <span class="has-text-grey"> comments.</span>
                </p>
              </div>
              <nav class="level">
                <div class="level-left" />
                <div class="level-right">
                  <a class="level-item" aria-label="reply">
                    <b-button type="is-danger" @click="reset">Cancel</b-button>
                  </a>
                  <a class="level-item" aria-label="like">
                    <b-button type="is-success" @click="save">Validate</b-button>
                  </a>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import router from "@/router";

export default {
  props: ["post"],
  methods: {
    reset() {
      this.$emit("reset");
    },
    save() {
      store.dispatch("tiktok/savePost", this.post);
      router.push("/dashboard");
    },
  },
};
</script>

<style scoped></style>
