<template>
  <div class="columns is-centered">
    <div class="column is-half">
      <div class="box">
        <article class="media">
          <div class="media-left">
            <figure v-if="song.cover !== null" class="image is-128x128">
              <img class="is-rounded" :src="song.cover" alt="Image" />
            </figure>
          </div>
          <div class="media-content">
            <div class="content">
              <p>
                <span class="title">{{ song.name }}</span>
              </p>
              <p>
                <span class="subtitle">{{ song.posts | formatNumber }} posts</span>
              </p>
              <p>
                <b-field label="Get notified when your song reach a certain limit">
                  <b-switch v-model="hasLimit" true-value="Yes" false-value="No">
                    {{ hasLimit }}
                  </b-switch>
                </b-field>
                <b-field v-if="hasLimit === 'Yes'" label="Videos Posts">
                  <b-input v-model="limit" placeholder="Videos Posts" min="0" type="number" />
                </b-field>
              </p>
            </div>
            <nav class="level">
              <div class="level-left" />
              <div class="level-right">
                <a class="level-item" aria-label="reply">
                  <b-button type="is-danger" @click="reset">Cancel</b-button>
                </a>
                <a class="level-item" aria-label="like">
                  <b-button type="is-success" @click="save">Validate</b-button>
                </a>
              </div>
            </nav>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-mutating-props */

import store from "@/store";
import router from "@/router";
// hasLimit
export default {
  props: ["song"],
  data() {
    return {
      hasLimit: "No",
      limit: this.song.posts,
    };
  },
  destroyed() {
    this.$store.dispatch("tiktok/clearTracking");
  },
  methods: {
    reset() {
      this.$emit("reset");
    },
    save() {
      //
      if (this.hasLimit === "Yes") {
        this.song.notification = true;
        this.song.limit = parseInt(this.limit);
      } else {
        this.song.notification = false;
        this.song.limit = 0;
      }
      store.dispatch("tiktok/saveMusic", this.song);
      router.push("/songs");
    },
  },
};
</script>

<style></style>
