<template>
  <div class="hero-body">
    <div v-if="!canAddSearch">
      <div class="columns is-centered">
        <div class="column is-half has-text-centered">
          <img class="svg-custom" src="../../assets/upgrade.svg" />
        </div>
      </div>
      <div class="columns container is-centered">
        <div class="column is-half has-text-centered subtitle">
          Want to track more videos ?
          <p><router-link to="/setting"> Upgrade </router-link>your account.</p>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-if="post === null" class="container">
        <div class="columns is-centered">
          <div class="column is-8 box">
            <h1 class="title has-text-centered">
              Enter a channel url :
            </h1>
            <div class="columns is-centered">
              <div class="column">
                <form @submit.prevent="search">
                  <b-field grouped>
                    <b-input
                      v-model="url"
                      placeholder="e.g. https://www.youtube.com/channel/UCHdMoJivRrKvVBndmkl0_Ow"
                      required
                      expanded
                      size="is-medium"
                    />
                    <p class="control">
                      <button v-if="!loading" class="button is-primary is-medium" type="submit">
                        Track
                      </button>
                      <button
                        v-if="loading"
                        class="button is-primary is-loading is-medium"
                        type="submit"
                        @click="search"
                      >
                        Track
                      </button>
                    </p>
                  </b-field>
                  <span v-if="error" class="has-text-danger is-size-6"
                    >Please provide a correct video url</span
                  >
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <YoutubeResult :post="post" @reset="reset" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import axios from "axios";
import YoutubeResult from "./YoutubeResult.vue";

export default {
  components: { YoutubeResult },

  data() {
    return {
      post: null,
      loading: false,
      error: false,
      url: "",
    };
  },
  computed: {
    ...mapGetters("user", ["user"]),
    ...mapGetters("tiktok", ["tiktok"]),
    canAddSearch() {
      if (this.user.plan === "free") {
        return false;
      }
      if (this.tiktok.length > this.user.limit) {
        return false;
      }
      return true;
    },
  },
  mounted() {
    this.$amplitude.logEvent("Search youtube ");
  },
  methods: {
    search() {
      const vm = this;
      this.loading = true;
      this.error = false;
      const res = this.url.split("channel/");
      if (res.length < 1) {
        this.error = true;
        this.loading = false;
        return;
      }
      const d = res[1];
      // Clean url
      console.log(d);
      axios
        .get(`${process.env.VUE_APP_YOUTUBE_SERVICE}/getChannelInfo?channel_id=${d}`)
        .then(response => {
          vm.post = response.data;
          // handle success
          vm.loading = false;
        })
        .catch(error => {
          // this.error = error.data;
          console.log(error);
          vm.loading = false;
          vm.error = true;
        });
    },
    reset() {
      this.post = null;
    },
  },
};
</script>

<style></style>
