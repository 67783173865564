<template>
  <div>
    <section v-if="tiktokUser === null" />
    <section v-else class="section">
      <div class="columns is-centered">
        <div class="column has-text-centered">
          <figure class="image container is-128x128">
            <img class="is-rounded" :src="tiktokUser.avatar" />
          </figure>
          <p class="title has-margin-top-10">
            {{ tiktokUser.nick_name }}
          </p>
          <p class="subtitle">@{{ tiktokUser.unique_id }}</p>
          <p v-if="tiktokUser.modification_date !== undefined" class="has-text-grey-light">
            Last updated {{ getLastModification }} ago
          </p>
        </div>
      </div>
      <div v-if="tiktokUser.modification_date === undefined">
        <div class="columns is-centered">
          <div class="column is-half has-text-centered">
            <span class="subtitle"
              >We are fetching your data, it takes up to 24h to be updated, please come back
              tomorrow!</span
            >
          </div>
        </div>
        <div class="columns is-centered">
          <div class="column is-half">
            <b-progress type="is-primary" />
          </div>
        </div>
      </div>
      <div v-else>
        <div class="columns is-centered">
          <div class="column has-text-centered">
            <b-tooltip
              label="Get email notification, when a post or the user
                  hit a overperforming score set here."
              position="is-top"
            >
              <b-button
                v-if="canAddAlert"
                type="is-primary is-outlined is-rounded"
                @click="createAlert()"
              >
                Create Viral Alert
              </b-button>
              <div v-else>
                <b-button type="is-primary is-outlined is-rounded" disabled>
                  Create Viral Alert
                </b-button>
                <br />
                <span class="is-size-7"
                  ><router-link to="/setting">
                    Upgrade
                  </router-link>
                  your account for more alerts
                </span>
              </div>
            </b-tooltip>
          </div>
        </div>
        <UserAlert v-if="newAlert" />
        <div v-else>
          <div class="box">
            <b-tabs>
              <b-tab-item label="Overview">
                <UserDetail />
              </b-tab-item>
              <b-tab-item label="Songs">
                <UserSongs />
              </b-tab-item>
              <b-tab-item label="Reports">
                <UserReport />
              </b-tab-item>
              <b-tab-item label="Videos">
                <UserVideo />
              </b-tab-item>
              <b-tab-item label="Alerts">
                <AlertTable />
              </b-tab-item>
            </b-tabs>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import UserDetail from "@/components/user/UserDetail";
import UserVideo from "@/components/user/UserVideo";
import UserAlert from "@/components/user/UserAlert";
import AlertTable from "@/components/user/AlertTable";
import UserSongs from "@/components/user/UserSongs";
import UserReport from "@/components/user/UserReport";

const prettyMilliseconds = require("pretty-ms");

export default {
  components: {
    UserDetail,
    UserVideo,
    UserAlert,
    AlertTable,
    UserReport,
    UserSongs,
  },

  data() {
    return {
      loading: false,
      newAlert: false,
    };
  },
  computed: {
    ...mapGetters("user", ["user"]),
    ...mapGetters("tiktok", ["tiktokUser"]),
    ...mapGetters("tiktok", ["getAlerts"]),
    canAddAlert() {
      if (this.user.plan === "free") {
        return false;
      }
      if (this.getAlerts.length > this.user.alerts_limit) {
        return false;
      }
      return true;
    },
    getLastModification() {
      const startDate = new Date();
      const last = new Date(this.tiktokUser.modification_date.seconds * 1000);
      return prettyMilliseconds(startDate - last);
    },
    isUpdating() {
      return true;
    },
  },
  mounted() {
    this.$amplitude.logEvent("display user Result");
    this.$store.dispatch("tiktok/getTracking", { id: this.$route.params.id });
    this.$store.dispatch("tiktok/getSearchAlerts", {
      search_id: this.$route.params.id,
    });
  },
  destroyed() {
    this.$store.dispatch("tiktok/clearTracking");
  },
  methods: {
    createAlert() {
      this.$amplitude.logEvent("Create alert");
      this.newAlert = true;
    },
    getRouterLink() {
      return `/alert/user/${this.$route.params.id}`;
    },
  },
};
</script>

<style>
.blur {
  -webkit-filter: blur(10px);
  filter: blur(10px);
}
</style>
