<template>
  <div>
    <section class="section">
      <b-tabs position="is-centered" class="has-background-white" type="is-toggle" expanded>
        <b-tab-item label="Hearts">
          <section class="section">
            <div class="box">
              <line-chart :data="getDiggData()" />
              <p class="subtitle has-text-centered has-text-grey">
                Hearts gained by day
              </p>
              <div class="columns">
                <div class="column is-8 is-offset-2">
                  <b-table
                    :data="getDiggTable()"
                    :striped="true"
                    :bordered="true"
                    :mobile-cards="true"
                    default-sort="date"
                  >
                    <template slot-scope="props">
                      <b-table-column field="date" label="Date" sortable :custom-sort="sortByDate">
                        {{ props.row.date.toLocaleDateString() }}
                      </b-table-column>

                      <b-table-column field="evolution" label="Hearts gained by day" sortable>
                        {{ props.row.evolution | formatNumber }}
                      </b-table-column>
                    </template>
                  </b-table>
                </div>
              </div>
            </div>
          </section>
        </b-tab-item>
        <b-tab-item label="Comments">
          <section class="section">
            <div class="box">
              <line-chart :data="getCommentData()" />
              <p class="subtitle has-text-centered has-text-grey">
                Hearts gained by day
              </p>
              <div class="columns">
                <div class="column is-8 is-offset-2">
                  <b-table
                    :data="getCommentTable()"
                    :striped="true"
                    :bordered="true"
                    :mobile-cards="true"
                    default-sort="date"
                  >
                    <template slot-scope="props">
                      <b-table-column field="date" label="Date" sortable :custom-sort="sortByDate">
                        {{ props.row.date.toLocaleDateString() }}
                      </b-table-column>

                      <b-table-column field="evolution" label="Comments gained by day" sortable>
                        {{ props.row.evolution | formatNumber }}
                      </b-table-column>
                    </template>
                  </b-table>
                </div>
              </div>
            </div>
          </section>
        </b-tab-item>
        <b-tab-item label="Shares">
          <section class="section">
            <div class="box">
              <line-chart :data="getShareData()" />
              <p class="subtitle title has-text-centered has-text-grey">
                Shareds gained by day
              </p>
              <div class="columns">
                <div class="column is-8 is-offset-2">
                  <b-table
                    :data="getShareTable()"
                    :striped="true"
                    :bordered="true"
                    :mobile-cards="true"
                    default-sort="date"
                  >
                    <template slot-scope="props">
                      <b-table-column field="date" label="Date" sortable :custom-sort="sortByDate">
                        {{ props.row.date.toLocaleDateString() }}
                      </b-table-column>

                      <b-table-column field="evolution" label="Shareds gained by day" sortable>
                        {{ props.row.evolution | formatNumber }}
                      </b-table-column>
                    </template>
                  </b-table>
                </div>
              </div>
            </div>
          </section>
        </b-tab-item>
        <b-tab-item label="Views">
          <section class="section">
            <div class="box">
              <line-chart :data="getViewsData()" />
              <p class="subtitle title has-text-centered has-text-grey">
                Views gained by day
              </p>
              <div class="columns">
                <div class="column is-8 is-offset-2">
                  <b-table
                    :data="getViewsTable()"
                    :striped="true"
                    :bordered="true"
                    :mobile-cards="true"
                    default-sort="date"
                  >
                    <template slot-scope="props">
                      <b-table-column field="date" label="Date" sortable :custom-sort="sortByDate">
                        {{ props.row.date.toLocaleDateString() }}
                      </b-table-column>

                      <b-table-column field="evolution" label="Views gained by day" sortable>
                        {{ props.row.evolution | formatNumber }}
                      </b-table-column>
                    </template>
                  </b-table>
                </div>
              </div>
            </div>
          </section>
        </b-tab-item>
      </b-tabs>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      columns: [
        {
          field: "date",
          label: "Date",
        },
        {
          field: "evolution",
          label: "Evolution",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("tiktok", ["getPost"]),
  },
  methods: {
    getDiggData() {
      const data = {};
      this.getPost.data.forEach(element => {
        if (element.digg !== undefined) {
          const d = new Date(element.scan_date.seconds * 1000).toString();
          data[d] = element.digg;
        }
      });
      return data;
    },
    getDiggTable() {
      const data = [];
      this.getPost.data.forEach(element => {
        if (element.digg !== undefined) {
          const d = new Date(element.scan_date.seconds * 1000);
          const t = { date: d, evolution: element.digg };
          data.push(t);
        }
      });
      return data;
    },
    getShareData() {
      const data = {};
      this.getPost.data.forEach(element => {
        if (element.share !== undefined) {
          const d = new Date(element.scan_date.seconds * 1000).toString();
          data[d] = element.share;
        }
      });
      return data;
    },
    getShareTable() {
      const data = [];
      this.getPost.data.forEach(element => {
        if (element.share !== undefined) {
          const d = new Date(element.scan_date.seconds * 1000);
          const t = { date: d, evolution: element.share };
          data.push(t);
        }
      });
      return data;
    },
    getViewsData() {
      const data = {};
      this.getPost.data.forEach(element => {
        if (element.views !== undefined) {
          const d = new Date(element.scan_date.seconds * 1000).toString();
          data[d] = element.views;
        }
      });
      return data;
    },
    getViewsTable() {
      const data = [];
      this.getPost.data.forEach(element => {
        if (element.views !== undefined) {
          const d = new Date(element.scan_date.seconds * 1000);
          const t = { date: d, evolution: element.views };
          data.push(t);
        }
      });
      return data;
    },
    getCommentData() {
      const data = {};
      this.getPost.data.forEach(element => {
        if (element.comment !== undefined) {
          const d = new Date(element.scan_date.seconds * 1000).toString();
          data[d] = element.comment;
        }
      });
      return data;
    },
    getCommentTable() {
      const data = [];
      this.getPost.data.forEach(element => {
        if (element.comment !== undefined) {
          const d = new Date(element.scan_date.seconds * 1000);
          const t = { date: d, evolution: element.comment };
          data.push(t);
        }
      });
      return data;
    },
    sortByDate(a, b, isAsc) {
      if (isAsc) {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
      }
      return new Date(a.date).getTime() - new Date(b.date).getTime();
    },
  },
};
</script>

<style></style>
