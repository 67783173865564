<template>
  <div class="hero-body">
    <div v-if="!canAddSearch">
      <div class="columns is-centered">
        <div class="column is-half has-text-centered">
          <img class="svg-custom" src="../../assets/upgrade.svg" />
        </div>
      </div>
      <div class="columns container is-centered">
        <div class="column is-half has-text-centered subtitle">
          Want to track more users ?
          <p><router-link to="setting"> Upgrade </router-link>your account.</p>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-if="data === null" class="container">
        <div class="columns is-centered">
          <div class="column is-8 box">
            <h1 class="title has-text-centered">
              Enter a tiktok username :
            </h1>
            <div class="columns is-centered">
              <div class="column">
                <form @submit.prevent="search">
                  <b-field grouped>
                    <b-input
                      v-model="username"
                      placeholder="Tiktok username"
                      required
                      expanded
                      size="is-medium"
                    />
                    <p class="control">
                      <button v-if="!loading" class="button is-primary is-medium" type="submit">
                        Track
                      </button>
                      <button
                        v-if="loading"
                        class="button is-primary is-loading is-medium"
                        type="submit"
                        @click="search"
                      >
                        Track
                      </button>
                    </p>
                  </b-field>
                  <span v-if="error" class="has-text-danger is-size-6"
                    >Please provide a correct username</span
                  >
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <TiktokUser :user="data" @reset="reset" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import TiktokUser from "@/components/user/TikTokUser";

export default {
  components: { TiktokUser },

  data() {
    return {
      data: null,
      loading: false,
      error: false,
      username: "",
    };
  },
  computed: {
    ...mapGetters("user", ["user"]),
    ...mapGetters("tiktok", ["tiktok"]),
    canAddSearch() {
      if (this.user.plan === "free") {
        return false;
      }
      if (this.tiktok.length > this.user.limit) {
        return false;
      }
      return true;
    },
  },
  mounted() {
    this.$amplitude.logEvent("Search Users");
  },
  methods: {
    search() {
      const vm = this;
      this.loading = true;
      this.error = false;
      axios
        .get(`${process.env.VUE_APP_API}/tiktok/${this.username}`)
        .then(response => {
          vm.data = response.data;
          // handle success
          vm.loading = false;
        })
        .catch(error => {
          // this.error = error.data;
          console.log(error);
          vm.loading = false;
          vm.error = true;
        });
    },
    reset() {
      this.data = null;
    },
  },
};
</script>

<style></style>
