<template>
  <section class="section">
    <div class="box">
      <p class="subtitle has-text-centered">
        Top Hashtag used by user
      </p>
      <div class="columns">
        <div class="column is-8 is-offset-2">
          <div class="box">
            <b-table
              v-if="user.plan === 'pro'"
              :data="data"
              :loading="loading"
              :paginated="true"
              per-page="15"
              :striped="true"
              :bordered="true"
              default-sort="count"
              default-sort-direction="desc"
            >
              <template slot-scope="props">
                <b-table-column field="hashtag" label="Hashtag" numeric sortable>
                  {{ props.row.hashtag }}
                </b-table-column>
                <b-table-column field="count" label="Count" sortable>
                  {{ props.row.count }}
                </b-table-column>
              </template>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <p>
                      <b-icon icon="update" size="is-large" />
                    </p>
                    <p>
                      Data will be updated soon
                    </p>
                  </div>
                </section>
              </template>
            </b-table>
            <div v-else>
              <b-table :data="[]" :striped="true" :bordered="true">
                <template slot="empty">
                  <section class="section">
                    <div class="content has-text-grey has-text-centered">
                      <p>
                        <b-icon icon="transfer-up" size="is-large" />
                      </p>
                      <p>
                        <router-link to="/setting"> Upgrade </router-link>your >your >your >your
                        >your >your >your >your account to pro.
                      </p>
                    </div>
                  </section>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import firebase from "firebase";

import { mapGetters } from "vuex";

export default {
  data() {
    return {
      data: [],
      loading: false,
      sortField: "vote_count",
      sortOrder: "desc",
      defaultSortOrder: "desc",
      perPage: 20,
    };
  },

  computed: {
    ...mapGetters("tiktok", ["tiktokUser"]),
    ...mapGetters("user", ["user"]),
  },
  mounted() {
    if (this.user.plan !== "pro") {
      return;
    }
    const id = this.tiktokUser.unique_id;
    this.loading = true;
    const vm = this;
    firebase
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(idToken => {
        const axiosConfig = {
          headers: {
            Authorization: `Bearer ${idToken}`,
            "Access-Control-Allow-Origin": "*",
          },
        };
        axios
          .get(`${process.env.VUE_APP_USER_SERVICE}/user/hashtags/${id}`, axiosConfig)
          .then(response => {
            vm.data = response.data;
            vm.loading = false;
          })
          .catch(error => {
            // this.error = error.data;
            vm.data = [];
            vm.loading = false;
            console.log(error);
          });
      })
      .catch(error => {
        console.log(error);
        vm.loading = false;
      });
  },
};
</script>

<style></style>
