<template>
  <div>
    <div class="columns">
      <div class="column is-one-third">
        <div class="box">
          <div class="columns">
            <div class="column is-one-fifth">
              <b-icon icon="video" size="is-large" />
            </div>
            <div class="column">
              <p class="is-size-5 has-text-grey">
                Videos
              </p>
              <p class="is-size-3 has-text-dark">
                {{ tiktokUser.video | formatNumber }}
              </p>
              <p />
            </div>
          </div>
        </div>
      </div>
      <div class="column is-one-third">
        <div class="box">
          <div class="columns">
            <div class="column is-one-fifth">
              <b-icon icon="users" size="is-large" type="is-info" pack="fas" />
            </div>
            <div class="column">
              <p class="is-size-5 has-text-grey">
                Fans
              </p>
              <p class="is-size-3 has-text-dark">
                {{ tiktokUser.fans | formatNumber }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-one-third">
        <div class="box">
          <div class="columns">
            <div class="column is-one-fifth">
              <b-icon icon="user" size="is-large" type="is-info" pack="fas" />
            </div>
            <div class="column">
              <p class="is-size-5 has-text-grey">
                Following
              </p>
              <p class="is-size-3 has-text-dark">
                {{ tiktokUser.following | formatNumber }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-one-third">
        <div class="box">
          <div class="columns">
            <div class="column is-one-fifth">
              <b-icon icon="account-convert" size="is-large" type="is-twitter" />
            </div>
            <div class="column">
              <p class="is-size-5 has-text-grey">
                Engagement rate
                <b-tooltip
                  label="We use the following formula to calculate the Engagement rate : Total of likes and comments for last 100 videos divided by the numbers of fans divided by 100"
                  type="is-light"
                  multilined
                >
                  <b-icon icon="help-circle" size="is-small" />
                </b-tooltip>
              </p>
              <p v-if="tiktokUser.engagement_rate === 0" class="is-size-7 has-text-dark">
                Data will be updated soon
                <br />Please come back tomorrow
              </p>
              <p v-else class="is-size-3 has-text-dark">
                {{ (tiktokUser.engagement_rate * 100).toFixed(2) }} %
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-one-third">
        <div class="box">
          <div class="columns">
            <div class="column is-one-fifth">
              <b-icon icon="heart" size="is-large" type="is-danger" />
            </div>
            <div class="column">
              <p class="is-size-5 has-text-grey">
                Total Heart
              </p>

              <p class="is-size-3 has-text-dark">
                {{ tiktokUser.heart | formatNumber }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-one-third">
        <div class="box">
          <div class="columns">
            <div class="column is-one-fifth">
              <b-icon icon="heartbeat" size="is-large" type="is-danger" pack="fas" />
            </div>
            <div class="column">
              <p class="is-size-5 has-text-grey">
                Average likes
              </p>
              <p class="is-size-3 has-text-dark">
                {{ tiktokUser.average_like | formatNumber }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-one-third">
        <div class="box">
          <div class="columns">
            <div class="column is-one-fifth">
              <b-icon icon="comment" size="is-large" type="is-success" />
            </div>
            <div class="column">
              <p class="is-size-5 has-text-grey">
                Average comments
              </p>
              <p v-if="tiktokUser.average_comment === 0" class="is-size-7 has-text-dark">
                Data will be updated soon
                <br />Please come back tomorrow
              </p>

              <p v-else class="is-size-3 has-text-dark">
                {{ tiktokUser.average_comment | formatNumber }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-one-third">
        <total-comments />
      </div>
      <div class="column is-one-third">
        <total-shares />
      </div>
    </div>
    <div class="columns">
      <div class="column is-one-third">
        <div class="box">
          <div class="columns">
            <div class="column is-one-fifth">
              <b-icon icon="play" size="is-large" type="is-succes" />
            </div>
            <div class="column">
              <p class="is-size-5 has-text-grey">
                Views
                <b-tooltip label="Total views for the last 100 videos" type="is-light" multilined>
                  <b-icon icon="help-circle" size="is-small" />
                </b-tooltip>
              </p>
              <p class="is-size-3 has-text-dark">
                {{ tiktokUser.views | formatNumber }}
              </p>
              <p />
            </div>
          </div>
        </div>
      </div>
    </div>
    <TiktokStat />
    <TopHashtag />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import axios from "axios";
import TiktokStat from "@/components/user/TiktokStat";
import TopHashtag from "@/components/user/TopHashtag";

import TotalComments from "@/components/user/TotalComments";
import TotalShares from "@/components/user/TotalShares";

export default {
  components: {
    TiktokStat,
    TotalComments,
    TotalShares,
    TopHashtag,
  },

  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters("user", ["user"]),
    ...mapGetters("tiktok", ["tiktokUser"]),
  },
  mounted() {
    this.$amplitude.logEvent("display user detail");
  },
  methods: {
    exportToCSV() {
      const id = this.tiktokUser.unique_id;
      const user = this.$store.getters["auth/authenticatedUser"].uid;
      this.loading = true;
      const vm = this;
      axios
        .get(`${process.env.VUE_APP_API}/csv/user/?id=${id}&user=${user}`)
        .then(response => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", `${id}.csv`);
          document.body.appendChild(fileLink);

          fileLink.click();
          // handle success
          vm.loading = false;
        })
        .catch(error => {
          // this.error = error.data;
          console.log(error);
          vm.loading = false;
        });
    },
  },
};
</script>

<style>
.blur {
  -webkit-filter: blur(10px);
  filter: blur(10px);
}
</style>
