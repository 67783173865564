<template>
  <div>
    <section class="section">
      <div class="columns is-centered">
        <div class="column has-text-centered">
          <figure v-if="getMusic.getCover !== ''" class="image container is-128x128">
            <img class="is-rounded" :src="getMusic.cover" />
          </figure>
          <p class="title has-margin-top-10">
            <span v-if="!edit" class="title is-3">
              {{ name }}
              <b-button icon-right="pencil" @click="edit = true" />
            </span>

            <span v-if="edit" class="title is-3">
              <b-field position="is-centered">
                <b-input v-model="name" size="is-medium" />
                <b-button size="is-medium" type="is-primary" @click="setLabel()">Save</b-button>
              </b-field>
            </span>
          </p>
          <p class="subtitle">by {{ getMusic.author }}</p>
          <p v-if="getMusic.modification_date !== undefined" class="has-text-grey-light">
            Last updated {{ getLastModification }} ago
          </p>
        </div>
      </div>

      <div class="columns is-centered">
        <div class="column has-text-centered">
          <b-tooltip
            label="Coming soon ! Get email notification, when a post or the song hits a overperforming score set here."
            position="is-top"
          >
            <b-button type="is-primary is-rounded is-outlined" disabled>
              Create Viral Alert
            </b-button>
          </b-tooltip>
        </div>
      </div>
      <p class="has-text-centered title is-3" />
      <div>
        <div v-if="getMusic.modification_date === undefined">
          <div class="columns is-centered">
            <div class="column is-half has-text-centered">
              <span class="subtitle"
                >We are fetching your data, it takes up to 24h to be updated, please come back
                tomorrow!</span
              >
            </div>
          </div>
          <div class="columns is-centered">
            <div class="column is-half">
              <b-progress type="is-primary" />
            </div>
          </div>
        </div>
        <div v-else class="box">
          <b-tabs :destroy-on-hide="true">
            <b-tab-item label="Overview">
              <div class="columns is-centered">
                <div class="column is-one-third">
                  <div class="box">
                    <div class="columns">
                      <div class="column is-one-fifth">
                        <b-icon icon="video" size="is-large" type="is-success" />
                      </div>
                      <div class="column">
                        <p class="is-size-5 has-text-grey">
                          Number of post
                        </p>
                        <p class="is-size-3 has-text-dark">
                          {{ getMusic.posts | formatNumber }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <MusicGraph />
            </b-tab-item>
            <b-tab-item label="Reports">
              <Report />
            </b-tab-item>
            <b-tab-item label="Videos">
              <music-video />
            </b-tab-item>
          </b-tabs>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";

import MusicGraph from "@/components/music/MusicGraph";
import MusicVideo from "@/components/music/MusicVideo";
import Report from "@/components/music/Report";

const prettyMilliseconds = require("pretty-ms");

export default {
  components: { MusicGraph, MusicVideo, Report },
  data() {
    return {
      loading: false,
      edit: false,
      newName: "",
    };
  },
  computed: {
    name: {
      get() {
        if (this.getMusic.label !== undefined || this.getMusic.label === null) {
          return this.getMusic.label;
        }
        return this.getMusic.name;
      },
      set(newValue) {
        this.getMusic.label = newValue;
      },
    },
    getLastModification() {
      const startDate = new Date();
      const last = new Date(this.getMusic.modification_date.seconds * 1000);
      return prettyMilliseconds(startDate - last);
    },
    ...mapGetters("tiktok", ["getMusic"]),
  },
  mounted() {
    this.$amplitude.logEvent("display song Result");
    this.$store.dispatch("tiktok/refreshMusic", { id: this.$route.params.id });
  },
  methods: {
    // exportToCSV() {
    //   const {id} = this.getMusic;
    //   const user = this.$store.getters['auth/authenticatedUser'].uid;
    //   this.loading = true;
    //   const vm = this;
    //   axios
    //       .get(`${process.env.VUE_APP_API}/csv/song/?id=${id}&user=${user}`)
    //       .then((response) => {
    //         const fileURL = window.URL.createObjectURL(new Blob([response.data]));
    //         const fileLink = document.createElement('a');

    //         fileLink.href = fileURL;
    //         fileLink.setAttribute('download', `${vm.getMusic.name}.csv`);
    //         document.body.appendChild(fileLink);

    //         fileLink.click();
    //         // handle success
    //         vm.loading = false;
    //       })
    //       .catch((error) => {
    //       // this.error = error.data;
    //         console.log(error);
    //         vm.loading = false;
    //       });
    // },
    setLabel() {
      const data = { id: this.$route.params.id, label: this.getMusic.label };
      store.dispatch("tiktok/updateMusicName", data);
      this.edit = false;
    },
  },
};
</script>

<style></style>
