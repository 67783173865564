<template>
  <section class="section">
    <b-tabs type="is-toggle-rounded" position="is-right" size="is-small">
      <b-tab-item label="Per Day">
        <column-chart :data="getPostsBar()" :download="true" thousands="," />
        <div class="columns">
          <div class="column has-text-right">
            <span class="subtitle has-text-centered has-text-grey">New posts by day</span>
          </div>
          <div class="column has-text-right">
            <b-button v-if="!loading" type="is-primary" @click="exportToCSV()">
              Export to csv
            </b-button>
            <b-button v-else type="is-primary" loading>
              Export to csv
            </b-button>
          </div>
        </div>

        <div class="columns">
          <div class="column is-8 is-offset-2">
            <b-table
              :data="getTable()"
              :striped="true"
              :bordered="true"
              :mobile-cards="true"
              default-sort="date"
              :paginated="true"
              per-page="15"
              pagination-position="bottom"
            >
              <template slot-scope="props">
                <b-table-column field="date" label="Date" sortable :custom-sort="sortByDate">
                  {{ props.row.date.toLocaleDateString() }}
                </b-table-column>

                <b-table-column label="New posts by day" sortable>
                  <span
                    :class="[
                      { 'has-text-danger': props.row.evolution <= 0 },
                      { 'has-text-success': props.row.evolution > 0 },
                    ]"
                    >{{ props.row.evolution | formatNumber }}</span
                  >
                </b-table-column>
              </template>
            </b-table>
          </div>
        </div>
      </b-tab-item>
      <b-tab-item label="Total">
        <line-chart :data="getTotalPosts()" :download="true" thousands="," />
        <div class="columns">
          <div class="column has-text-right">
            <span class="subtitle has-text-centered has-text-grey">Total posts</span>
          </div>
          <div class="column has-text-right">
            <b-button v-if="!loading" type="is-primary" @click="exportToCSVTotal()">
              Export to csv
            </b-button>
            <b-button v-else type="is-primary" loading>
              Export to csv
            </b-button>
          </div>
        </div>

        <div class="columns">
          <div class="column is-8 is-offset-2">
            <b-table
              :data="getTotalPostTable()"
              :striped="true"
              :bordered="true"
              :mobile-cards="true"
              default-sort="date"
              :paginated="true"
              per-page="15"
              pagination-position="bottom"
            >
              <template slot-scope="props">
                <b-table-column field="date" label="Date" sortable :custom-sort="sortByDate">
                  {{ props.row.date.toLocaleDateString() }}
                </b-table-column>

                <b-table-column label="Total posts" sortable>
                  <span
                    :class="[
                      { 'has-text-danger': props.row.evolution <= 0 },
                      { 'has-text-success': props.row.evolution > 0 },
                    ]"
                    >{{ props.row.evolution | formatNumber }}</span
                  >
                </b-table-column>
              </template>
            </b-table>
          </div>
        </div>
      </b-tab-item>
    </b-tabs>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters("tiktok", ["getMusic"]),
  },
  methods: {
    getTotalPosts() {
      const data = {};
      this.getMusic.top_videos.forEach(element => {
        if (element.total_posts !== undefined) {
          const d = new Date(element.scan_date.seconds * 1000).toString();
          data[d] = element.total_posts;
        }
      });
      return data;
    },
    getPostsBar() {
      const data = [];
      this.getMusic.top_videos.forEach(element => {
        if (element.posts !== undefined) {
          const d = new Date(element.scan_date.seconds * 1000);
          data.push([d, element.posts]);
        }
      });
      const d = data.sort((a, b) => a[0] - b[0]);
      d.forEach(element => {
        element[0] = element[0].toLocaleDateString();
      });
      return d;
    },
    getTable() {
      const data = [];
      this.getMusic.top_videos.forEach(element => {
        if (element.posts !== undefined) {
          const d = new Date(element.scan_date.seconds * 1000);
          const i = { date: d, evolution: element.posts };
          data.push(i);
        }
      });
      return data;
    },
    getTotalPostTable() {
      const data = [];
      this.getMusic.top_videos.forEach(element => {
        if (element.posts !== undefined) {
          const d = new Date(element.scan_date.seconds * 1000);
          const i = { date: d, evolution: element.total_posts };
          data.push(i);
        }
      });
      return data;
    },
    sortByDate(a, b, isAsc) {
      if (isAsc) {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
      }
      return new Date(a.date).getTime() - new Date(b.date).getTime();
    },
    exportToCSV() {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(this.getTable()[0]).join(";"),
        ...this.getTable().map(item => Object.values(item).join(";")),
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "export.csv");
      link.click();
    },

    exportToCSVTotal() {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(this.getTotalPostTable()[0]).join(";"),
        ...this.getTotalPostTable().map(item => Object.values(item).join(";")),
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "export.csv");
      link.click();
    },
  },
};
// total_posts
</script>

<style></style>
