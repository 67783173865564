<template>
  <section class="section">
    <div class="box">
      <p class="subtitle has-text-centered">
        Hashtag Videos
        <br /><br />
      </p>
      <div class="columns is-centered">
        <div class="column has-text-centered">
          <b-button
            v-if="!isLoading"
            type="is-primary is-rounded is-outlined"
            @click="exportToCSV()"
          >
            Export videos data to csv
          </b-button>
          <b-button v-else type="is-primary is-rounded is-outlined" loading>
            Export videos data to csv
          </b-button>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="box">
            <b-table
              :data="data"
              :loading="loading"
              :paginated="true"
              :striped="true"
              :bordered="true"
              default-sort="upload_date"
              default-sort-direction="desc"
            >
              <template slot-scope="props">
                <b-table-column label="video" width="25vh">
                  <a :href="getVideoLink(props.row.unique_id, props.row.video_id)" target="_blank">
                    <figure>
                      <img
                        class="tiktok"
                        :src="
                          `https://www.tiktok.com/api/img/?itemId=${props.row.video_id}&location=0&aid=1988`
                        "
                      />
                    </figure>
                  </a>
                </b-table-column>
                <b-table-column field="unique_id" label="User">
                  <a :href="getUserLink(props.row.unique_id)" target="_blank">
                    {{ props.row.unique_id }}
                  </a>
                </b-table-column>
                <b-table-column field="video_text" label="Description" width="320">
                  {{ getText(props.row.video_text) }}
                </b-table-column>
                <b-table-column field="create_time" label="Upload date" sortable>
                  {{ props.row.create_time }}
                </b-table-column>
                <b-table-column field="digg_count" label="Total likes" sortable numeric>
                  {{ props.row.digg_count | formatNumber }}
                </b-table-column>
                <b-table-column field="share_count" label="Total shares" sortable numeric>
                  {{ props.row.share_count | formatNumber }}
                </b-table-column>
                <b-table-column field="comment_count" label="Total comments" sortable numeric>
                  {{ props.row.comment_count | formatNumber }}
                </b-table-column>
                <b-table-column field="views" label="Total views" sortable numeric>
                  {{ props.row.views | formatNumber }}
                </b-table-column>
              </template>

              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <p>
                      <b-icon icon="update" size="is-large" />
                    </p>
                    <p>Data will be updated soon</p>
                  </div>
                </section>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

import axios from "axios";
import firebase from "firebase";
const ellipsize = require("ellipsize");

export default {
  data() {
    return {
      data: [],
      loading: false,
      sortOrder: "desc",
      defaultSortOrder: "desc",
      perPage: 20,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters("user", ["user"]),
    ...mapGetters("tiktok", ["tiktokUser"]),
  },
  mounted() {
    this.$amplitude.logEvent("display hashtags  videos");
    const id = this.tiktokUser.challenge_name;
    this.loading = true;
    const vm = this;
    firebase
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(idToken => {
        const axiosConfig = {
          headers: {
            Authorization: `Bearer ${idToken}`,
            "Access-Control-Allow-Origin": "*",
          },
        };
        axios
          .get(`${process.env.VUE_APP_USER_SERVICE}/hashtag/${id}/videos`, axiosConfig)
          .then(response => {
            vm.data = response.data;
            vm.loading = false;
          })
          .catch(error => {
            // this.error = error.data;
            vm.data = [];
            vm.loading = false;
            console.log(error);
          });
      })
      .catch(error => {
        console.log(error);
        vm.loading = false;
      });
  },
  methods: {
    getVideoLink(uniqueId, id) {
      return `https://www.tiktok.com/@${uniqueId}/video/${id}`;
    },
    getUserLink(uniqueId) {
      return `https://www.tiktok.com/@${uniqueId}`;
    },
    exportToCSV() {
      const id = this.tiktokUser.challenge_name;
      const user = this.$store.getters["auth/authenticatedUser"].uid;
      this.loading = true;
      const vm = this;
      axios
        .get(`${process.env.VUE_APP_API}/csv/hashtag/?id=${id}&user=${user}`)
        .then(response => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", `${id}.csv`);
          document.body.appendChild(fileLink);

          fileLink.click();
          // handle success
          vm.loading = false;
        })
        .catch(error => {
          // this.error = error.data;
          console.log(error);
          vm.loading = false;
        });
    },
    getText(txt) {
      return ellipsize(txt, 50, { truncate: true });
    },
  },
};
</script>

<style>
.inset {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.5);
}
.tiktok {
  max-width: 25vh;
  height: auto;
  width: auto;
  max-height: 35vh;
}
</style>
