<template>
  <div class="hero-body">
    <div v-if="!canAddSearch">
      <div class="columns is-centered">
        <div class="column is-half has-text-centered">
          <img class="svg-custom" src="../../assets/upgrade.svg" />
        </div>
      </div>
      <div class="columns container is-centered">
        <div class="column is-half has-text-centered subtitle">
          Want to track more songs ?
          <p><router-link to="/setting"> Upgrade </router-link>your account.</p>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-if="song === null" class="container">
        <div class="columns is-centered">
          <div class="column is-8 box">
            <h1 class="title has-text-centered">
              Enter a tiktok music url :
            </h1>
            <form @submit.prevent="search">
              <div class="columns is-centered">
                <div class="column has-text-centered">
                  <b-field>
                    <b-input
                      v-model="url"
                      placeholder="e.g. https://www.tiktok.com/music/Can-We-Kiss-Forever-6733128484777921281"
                      required
                      expanded
                      size="is-medium"
                    />
                  </b-field>
                  <h3 class="subtitle">
                    Select a region:
                  </h3>
                  <div class="columns is-centered">
                    <b-field position="is-centered">
                      <b-select v-model="region" placeholder="Select a subject" icon="earth">
                        <option value="us">
                          United States
                        </option>
                        <option value="gb">
                          Great Britain
                        </option>
                        <option value="ca">
                          Canada
                        </option>
                        <option value="au">
                          Australia
                        </option>
                        <option value="fr">
                          France
                        </option>
                        <option value="nl">
                          Netherlands
                        </option>
                        <option value="de">
                          Germany
                        </option>
                        <option value="in">
                          India
                        </option>
                        <option value="sg">
                          Singapore
                        </option>
                      </b-select>
                    </b-field>
                  </div>
                  <span v-if="error" class="has-text-danger is-size-6"
                    >Please provide a correct url or change the region</span
                  >
                </div>
              </div>
              <div class="columns is-centered">
                <div class="column is-half has-text-centered">
                  <button v-if="!loading" class="button is-primary is-medium" type="submit">
                    Track
                  </button>
                  <button
                    v-if="loading"
                    class="button is-primary is-loading is-medium"
                    type="submit"
                    @click="search"
                  >
                    Track
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div v-else>
        <music-result :song="song" @reset="reset" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import axios from "axios";
import MusicResult from "@/components/music/MusicResult";

export default {
  components: { MusicResult },

  data() {
    return {
      song: null,
      loading: false,
      error: false,
      url: "",
      region: "us",
    };
  },
  computed: {
    ...mapGetters("user", ["user"]),
    ...mapGetters("tiktok", ["tiktok"]),
    canAddSearch() {
      if (this.user.plan === "free") {
        return false;
      }
      if (this.tiktok.length > this.user.limit) {
        return false;
      }
      return true;
    },
  },
  mounted() {
    this.$amplitude.logEvent("Search Songs");
  },
  methods: {
    search() {
      const vm = this;
      this.loading = true;
      this.error = false;
      const res = this.url.split("music/");
      if (res.length < 1) {
        this.error = true;
        this.loading = false;
        return;
      }
      // Clean url
      axios
        .get(`${process.env.VUE_APP_API}/tiktok/music/${res[1]}?region=${this.region}`)
        .then(response => {
          vm.song = response.data;
          // handle success
          vm.loading = false;
        })
        .catch(error => {
          // this.error = error.data;
          console.log(error);
          vm.loading = false;
          vm.error = true;
        });
    },
    reset() {
      this.song = null;
    },
  },
};
</script>

<style></style>
