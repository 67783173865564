<template>
  <div>
    <section v-if="canAddAlert" class="section">
      <card-component title icon>
        <b-field horizontal label="Alert name">
          <b-input v-model="name" placeholder="Give your alert a name" />
        </b-field>
        <b-field horizontal label="On">
          <div class="block">
            <b-radio v-model="type" name="type" native-value="user">
              The user
            </b-radio>
            <b-radio v-model="type" name="type" native-value="post">
              Any posts
            </b-radio>
          </div>
        </b-field>
        <b-field v-if="type === 'post'" horizontal label="When the number of ">
          <div class="block">
            <b-radio v-model="kind" native-value="Views">
              Views
            </b-radio>
            <b-radio v-model="kind" native-value="CommentCount">
              Comments
            </b-radio>
            <b-radio v-model="kind" native-value="InteractionCount">
              Hearts
            </b-radio>
            <b-radio v-model="kind" name="name" native-value="ShareCount">
              Shares
            </b-radio>
          </div>
        </b-field>
        <b-field v-if="type === 'user'" horizontal label="When the number of">
          <div class="block">
            <b-radio v-model="kind" native-value="video">
              Posts
            </b-radio>
            <b-radio v-model="kind" native-value="fans">
              Followers
            </b-radio>
            <b-radio v-model="kind" native-value="followings">
              Following
            </b-radio>
            <b-radio v-model="kind" native-value="heart">
              Hearts
            </b-radio>
          </div>
        </b-field>
        <b-field horizontal label="Reach">
          <b-numberinput v-model="value" min="0" />
        </b-field>
        <b-field horizontal>
          <div class="control is-pulled-right">
            <b-button type="is-primary-light" @click="cancel()">
              Cancel
            </b-button>
            <b-button type="is-primary" @click="save()">
              Submit
            </b-button>
          </div>
        </b-field>
      </card-component>
    </section>
    <section v-else>
      <div class="columns is-centered">
        <div class="column is-half has-text-centered">
          <img class="svg-custom" src="../../assets/upgrade.svg" />
        </div>
      </div>
      <div class="columns container is-centered">
        <div class="column is-half has-text-centered subtitle">
          Want some alerts
          <p>
            <router-link to="/setting">
              Upgrade
            </router-link>
            your account !
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";
import CardComponent from "@/components/CardComponent";

export default {
  components: {
    CardComponent,
  },
  data() {
    return {
      name: "",
      type: "user",
      kind: "",
      value: 0,
    };
  },
  computed: {
    ...mapGetters("tiktok", ["tiktokUser"]),
    ...mapGetters("tiktok", ["getAlerts"]),
    ...mapGetters("user", ["user"]),
    canAddAlert() {
      if (this.user.plan === "free") {
        return false;
      }
      if (this.getAlerts.length > this.user.alerts_limit) {
        return false;
      }
      return true;
    },
  },
  methods: {
    cancel() {
      this.$parent.newAlert = false;
    },
    save() {
      const params = {
        name: this.name,
        type: this.type,
        kind: this.kind,
        value: this.value,
        search_id: this.$route.params.id,
        triggered: false,
        for: "user",
        alert_id: this.tiktokUser.unique_id,
      };
      store.dispatch("tiktok/saveAlert", params);
      this.$parent.newAlert = false;
      this.$amplitude.logEvent("Alert Saved");
    },
  },
};
</script>

<style></style>
